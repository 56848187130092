// src/App.js
import React, { useEffect, useState } from 'react';
import Storyblok from './storyblokService';
import { renderRichText } from '@storyblok/react';

const App = () => {
  const [story, setStory] = useState(null);

  useEffect(() => {
    Storyblok.get('cdn/stories/cloudflarepages-storyblok-poc', {})
      .then(response => {
        console.log(response.data.story); // Log the response to the console
        setStory(response.data.story);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const renderContent = (content) => {
    return content.map((blok) => {
      switch (blok.component) {
        case 'image':
          return (
            <div key={blok._uid} style={{ position: blok.position, width: blok.ratio }}>
              {blok.showTitle && <h2>{blok.title}</h2>}
              <img src={blok.image} alt={blok.alt} style={{ width: '100%' }} />
              {blok.showCaption && <p>{blok.caption}</p>}
              {blok.showCopyright && <p>&copy; {blok.copyright}</p>}
            </div>
          );
        case 'richtext':
          return <div key={blok._uid}>{renderRichText(blok)}</div>;
        case 'text':
          return (
            <div key={blok._uid} style={{ direction: blok.reverse ? 'rtl' : 'ltr' }}>
              <p>{blok.text}</p>
              {blok.footnote && <small>{blok.footnote}</small>}
            </div>
          );
        // Add more cases for other block types
        default:
          return null;
      }
    });
  };
//
  if (!story) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>{story.name}</h1>
      <div>{renderContent(story.content.body)}</div>
    </div>
  );
};

export default App;
